<template>
  <div class="supplier-oauth d-flex justify-center align-center">
    <div class="auth-box">
      <div class="authpage-header">
        <div class="mb-5 title">
          <img
            src="@/assets/img/logo-header.svg"
            alt="Wegang"
            class="auth-header-logo"
            width="200"
          />
        </div>
        <h1 v-if="isSignIn" class="title for-retailer">
          Connect to your VGANG Supplier account
        </h1>
        <h1 v-else class="title for-retailer">
          Sign up to VGANG as a Supplier
        </h1>
        <!-- <span class="sub for-retailer"
            >Login to suppliers dashboard to manage products & orders.</span
          > -->
      </div>

      <div class="errorMessage-container" v-if="showAuthError">
        <div class="material-icons error-icon">cancel</div>
        <div>
          Ops! something happened
          <span>{{ errorMessage }}</span>
        </div>
      </div>
      <div class="successMessage-container" v-if="showAuthSucces">
        <div class="material-icons success-icon">check</div>
        <div>
          Succeess
          <span>{{ successMessage }}</span>
        </div>
      </div>
      <div v-if="isSignIn" class="form-body">
        <div class="form">
          <CustomInput
            type="email"
            id="email"
            icon="email"
            label="Email address"
            v-model="login.email"
            :required="true"
          />
          <CustomPasswordInput
            type="password"
            id="password"
            icon="key"
            label="Password"
            v-model="login.password"
          />
          <div class="term-condition title text-center my-2 mb-5">
            By connecting your connecting your account you agree to the<span>
              <a
                href="https://vgang.io/terms"
                target="_blank"
                rel="noopener noreferrer"
                style="color: #008060 !important; font-weight: bold"
              >
                term and condition</a
              >
            </span>
          </div>
          <button class="vgang-button form-button" @click.prevent="UserSignIn">
            Sign in
          </button>

          <!-- <span class="forgot-pass">Forgot Password?</span> -->
        </div>
      </div>
      <div v-else class="form-body">
        <div class="form">
          <CustomInput
            type="email"
            id="email"
            icon="email"
            label="Email address"
            v-model="register.email"
            :required="true"
          />
          <CustomPasswordInput
            type="password"
            id="password"
            icon="key"
            label="Password"
            v-model="register.password"
          />
          <div class="term-condition title text-center my-2 mb-5">
            By signing up your connecting your account you agree to the<span>
              <a
                href="https://vgang.io/terms"
                target="_blank"
                rel="noopener noreferrer"
                style="color: #008060 !important; font-weight: bold"
              >
                term and condition</a
              >
            </span>
          </div>
          <button class="vgang-button form-button" @click.prevent="UserSignUp">
            Sign Up
          </button>

          <!-- <span class="forgot-pass">Forgot Password?</span> -->
        </div>
      </div>

      <div v-if="isSignIn" class="term-condition title text-center my-2 mb-5">
        Don't have an account ?

        <span id="sign-up" @click="GoToSignUp"> Sign up</span>
        .
      </div>
      <div v-else class="term-condition title text-center my-2 mb-5">
        Already have an account ?

        <span id="sign-up" @click="GoToSignIn"> Sign in</span>
        .
      </div>
    </div>
  </div>
</template>

<script>
import CustomInput from "@/components/CustomInput.vue";
import CustomPasswordInput from "@/components/CustomPasswordInput.vue";
import AuthService from "@/services/AuthenticationService";
// import { getVariable } from "@/utils/conditionalStoreVariables";
// Services
import { uuid } from "vue-uuid";

export default {
  data() {
    return {
      userType: "supplier",
      showAuthError: false,
      errorMessage: "",
      showAuthSucces: false,
      successMessage: "",
      uuid: uuid.v1(),
      isSignIn: true,
      accessToken: null,
      login: {
        email: "",
        password: "",
        action: "login",
        DeviceId: uuid.v1(),
      },
      register: {
        email: "",
        password: "",
        DeviceId: uuid.v1(),
        action: "register",
      },
    };
  },
  components: {
    CustomInput,
    CustomPasswordInput,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  beforeMount() {
    if (this.$route.query.signUp) this.GoToSignUp();
  },
  mounted() {
    const iFrameHost = process.env.VUE_APP_IFRAME_HOST;
    window.addEventListener(
      "message",
      (event) => {
        if (event.origin == iFrameHost) {
          if (event.data.type === "approve") {
            this.accessToken = event.data.data;

            if (event.data.message === "SignIn AccessToken Provided") {
              this.handleLogin(this.accessToken);
            } else if (event.data.message === "SignUp AccessToken Provided") {
              this.handleRegister(this.accessToken);
            }
          }
        } else {
          return;
        }
      },
      false
    );
  },
  methods: {
    GoToSignUp() {
      this.isSignIn = false;
    },
    GoToSignIn() {
      this.isSignIn = true;
    },
    async UserSignIn() {
      const iFrameHost = process.env.VUE_APP_IFRAME_HOST;

      window.opener.postMessage(
        { type: "request", message: "SignIn AccessToken" },
        iFrameHost
      );
    },
    async UserSignUp() {
      const iFrameHost = process.env.VUE_APP_IFRAME_HOST;
      window.opener.postMessage(
        { type: "request", message: "SignUp AccessToken" },
        iFrameHost
      );
    },
    async handleLogin(accesToken) {
      const iFrameHost = process.env.VUE_APP_IFRAME_HOST;
      try {
        //  todo send the login req here and is success send to the window.opener a message
        const response = await AuthService.sellerAssignUser({
          ...this.login,
          accesToken: accesToken,
          source: this.$router.history.current.query.src,
          sourceName: this.$router.history.current.query.src_name,
        });

        if (response.status !== 200) {
          this.showAuthError = true;
          this.errorMessage = "Please check your email and password";
        } else if (response.status === 200) {
          const data = await response.json();

          window.opener.postMessage(
            { type: "success", isSignUp: false, message: data.message },
            iFrameHost
          );
          this.showAuthSuccess = true;
          this.errorMessage = data.message;
          setTimeout(() => {
            window.close();
          }, 500);
        }
      } catch (error) {
        this.showAuthError = true;
        this.errorMessage = "Please try again";
      }
    },
    async handleRegister(accesToken) {
      const iFrameHost = process.env.VUE_APP_IFRAME_HOST;

      try {
        //  todo send the login req here and is success send to the window.opener a message
        const response = await AuthService.sellerAssignUser({
          ...this.register,
          accesToken: accesToken,
          source: this.$router.history.current.query.src,
          sourceName: this.$router.history.current.query.src_name,
        });

        if (response.status === 200) {
          const data = await response.json();

          window.opener.postMessage(
            { type: "success", isSignUp: true, message: data.message },
            iFrameHost
          );
          this.showAuthSuccess = true;
          this.successMessage = data.message;
          setTimeout(() => {
            window.close();
          }, 500);
        } else {
          this.showAuthError = true;
          this.errorMessage = "Please check your email and password";
        }
      } catch (error) {
        console.log("error", error);
        this.showAuthError = true;
        this.errorMessage = "Please try again";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-box {
  padding: 10px 50px !important;
}
.footer-mobile {
  display: hidden !important;
}
#sign-up {
  color: #008060 !important;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
}
</style>
